<template>
    <div>
        
        <b-row class="mt-1">
            <b-col xs="12">
                <statistic-card-horizontal
                    icon="UserIcon"
                    :statistic="formData.provider.name"
                    statistic-title="PROVEEDOR"
                />
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12">

                <b-card
                    class="mb-1"
                    header-tag="header" 
                    border-variant="primary" 
                    header-bg-variant="primary"
                >
                    <template #header>
                        <h5 class="mb-0 text-white"><strong>FONDO DE GARANTÍA ({{ moment(formData.start_date).subtract(1, 'months').format('MM/YYYY') }})</strong></h5>

                        <b-button-loading 
                            text="RECALCULAR"
                            :processing="gFProcessing"
                            variant="light"
                            @process-action="recalculateValues">
                        </b-button-loading>
                    </template>

                    <b-card-text class="pt-2">
                        <div class="text-center text-primary my-2" v-if="gFProcessing">
                            <b-spinner class="align-middle mb-1"></b-spinner>
                            <div><b>CARGANDO</b></div>
                        </div>

                        <b-row class="mb-1" v-else>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="percent"><strong>PORCENTAJE</strong></label>
                                <v-select 
                                    id="percent"
                                    v-model="data.percent"
					                :options="options.percents" 
                                />
                            </b-col>
                            
                            <b-col xs="12" md="4" class="mb-2">
                                <label for="sentinelRiskInitial">
                                    <strong>RIESGO SENTINEL INICIAL</strong>
                                    <b-badge :variant="data.sentinelRiskInitial.color" class="ml-1" v-if="data.sentinelRiskInitial"><strong>{{ data.sentinelRiskInitial.label }}</strong></b-badge>
                                </label>
                                <v-select 
                                    id="sentinelRiskInitial"
                                    v-model="data.sentinelRiskInitial"
					                :options="options.sentinelRisks" 
                                />
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="sentinelRiskFinal">
                                    <strong>RIESGO SENTINEL FINAL</strong>
                                    <b-badge :variant="data.sentinelRiskFinal.color" class="ml-1" v-if="data.sentinelRiskFinal"><strong>{{ data.sentinelRiskFinal.label }}</strong></b-badge>
                                </label>
                                <v-select 
                                    id="sentinelRiskFinal"
                                    v-model="data.sentinelRiskFinal"
					                :options="options.sentinelRisks" 
                                />
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="spreadsheet"><strong>PLANILLA</strong></label>
                                <b-form-input type="number" id="spreadsheet" v-model="data.spreadsheet"></b-form-input>
                            </b-col>
                            
                            <b-col xs="12" md="4" class="mb-2">
                                <label for="cts"><strong>CTS</strong></label>
                                <b-form-input id="cts" v-model="data.cts" class="font-weight-bold text-primary" disabled readonly></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="gratification"><strong>GRATIFICACIÓN</strong></label>
                                <b-form-input id="gratification" v-model="data.gratification" class="font-weight-bold text-primary" disabled readonly></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="thirdParties"><strong>TERCEROS</strong></label>
                                <b-form-input id="thirdParties" v-model="data.thirdParties" class="font-weight-bold text-primary" disabled readonly></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="total"><strong>TOTAL</strong></label>
                                <b-form-input id="total" v-model="data.total" class="font-weight-bold text-primary" disabled readonly></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="posted"><strong>CONTABILIZADO</strong></label>
                                <b-form-input type="number" id="posted" v-model="data.posted"></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="devolution"><strong>DEVOLUCIÓN</strong></label>
                                <b-form-input id="devolution" v-model="data.devolution" class="font-weight-bold text-primary" disabled readonly v-if="data.manualDevolutionAmountStatus == '0'"></b-form-input>
                                <b-form-input type="number" id="devolution" v-model="data.devolution" v-else></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="balance"><strong>SALDO</strong></label>
                                <b-form-input id="balance" v-model="data.balance" class="font-weight-bold text-primary" disabled readonly></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="requestedAmount"><strong>MONTO SOLICITADO</strong></label>
                                <b-form-input type="number" id="requestedAmount" v-model="data.requestedAmount"></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label for="amountApproved"><strong>MONTO APROBADO</strong></label>
                                <b-form-input type="number" id="amountApproved" v-model="data.amountApproved"></b-form-input>
                            </b-col>

                            <b-col xs="12" md="4" class="mb-2">
                                <label><strong>EVIDENCIAS</strong></label>
                                <BButtonUploadFile 
                                    :form-id="formData.id"
                                    :question-id="1"
                                />
                            </b-col>

                            <b-col xs="12" md="12" class="mb-2">
                                <label for="comments"><strong>COMENTARIO(S)</strong></label>
                                <b-form-textarea
                                    id="comments"
                                    v-model="data.comments"
                                    placeholder="Escriba un comentario ..."
                                    rows="3"
                                ></b-form-textarea>
                            </b-col>

                            <b-col xs="12" md="12">
                                <b-form-checkbox
                                    id="checkbox-1"
                                    v-model="data.manualDevolutionAmountStatus"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    <strong>INSERTAR EL MONTO DE "DEVOLUCIÓN" MANUALMENTE</strong>
                                </b-form-checkbox>
                            </b-col>

                        </b-row>
                    </b-card-text>
                </b-card>
                
                <validation-observer ref="refForm">

                    <b-card 
                        class="mb-0" 
                        header-tag="header" 
                        border-variant="primary" 
                        header-bg-variant="primary"
                    >
                        <template #header>
                            <h5 class="mb-0 text-white"><strong>DATOS ADICIONALES PARA EL INFORME ({{ moment(formData.start_date).subtract(1, 'months').format('MM/YYYY') }})</strong></h5>
                        </template>

                        <b-card-text class="pt-2">
                            <div class="text-center text-primary my-2" v-if="aDProcessing">
                                <b-spinner class="align-middle mb-1"></b-spinner>
                                <div><b>CARGANDO</b></div>
                            </div>

                            <b-row class="mb-1" v-else>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="reportPerson"><strong>PERSONA A QUIÉN SE DIRIGE EL INFORME</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="persona a quién se dirige el informe"
                                    >
                                        <b-form-input 
                                            id="reportPerson" 
                                            v-model="data.reportPerson" 
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="sentinelRating"><strong>PUNTAJE SENTINEL</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="puntaje sentinel"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="sentinelRating" 
                                            v-model="data.sentinelRating"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="financialDebt"><strong>DEUDA FINANCIERAS</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="deuda financieras"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="financialDebt" 
                                            v-model="data.financialDebt"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="laborDebt"><strong>DEUDA LABORAL</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="deuda laboral"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="laborDebt" 
                                            v-model="data.laborDebt"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="taxDebt"><strong>DEUDA TRIBUTARIA</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="deuda tributaria"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="taxDebt" 
                                            v-model="data.taxDebt"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="localSupplierDebt"><strong>DEUDA PROVEEDOR LOCAL</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="deuda proveedor local"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="localSupplierDebt" 
                                            v-model="data.localSupplierDebt"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="unregularizedProtests"><strong>PROTESTOS SIN REGULARIZAR</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="protestos sin regularizar"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="unregularizedProtests" 
                                            v-model="data.unregularizedProtests"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="unpaidDocuments"><strong>DOCUMENTOS IMPAGOS</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="documentos impagos"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="unpaidDocuments" 
                                            v-model="data.unpaidDocuments"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="4" class="mb-2">
                                    <label for="checker"><strong>FIRMA DIGITAL</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                        name="firma digital"
                                    >
                                        <v-select 
                                            id="checker"
                                            v-model="data.checker"
                                            :options="options.checkers"
                                            :state="errors.length > 0 ? false : null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>

                                <b-col xs="12" md="12" class="mb-2">
                                    <label for="thirdPartiesSuggestion"><strong>PROVISIÓN CON TERCEROS</strong></label>
                                    <validation-provider
                                        #default="{ errors }"
                                        :rules="`${ thirdPartiesSuggestionRules ? 'required': '' }`"
                                        name="provisión con terceros"
                                    >
                                        <b-form-input 
                                            type="number" 
                                            id="thirdPartiesSuggestion" 
                                            v-model="data.thirdPartiesSuggestion"
                                            :state="errors.length > 0 ? false : null"
                                            trim
                                        >
                                        </b-form-input>
                                        <small class="text-danger">{{ errors[0] }}</small> <br>
                                    </validation-provider>
                                    <small>Anotación: <strong class="text-primary">Ingresar el valor de "provisión con terceros" siempre y cuando el monto de "devolución" sea negativo. Caso contrario, dejar vacío el campo.</strong></small>
                                </b-col>

                                <b-col xs="12" md="12" class="mb-2">
                                    <label for="laborDebtDetails"><strong>DETALLES DE LA DEUDA LABORAL</strong></label>
                                    <b-form-input id="laborDebtDetails" v-model="data.laborDebtDetails" maxlength="35"></b-form-input>
                                    <small>Anotación: <strong class="text-primary">Ingresar el detalle de la deuda laboral (máximo 35 caracteres).</strong></small>
                                    <small class="textarea-counter-value float-right">
                                        <strong class="char-count">Caracteres: {{ data.laborDebtDetails ? data.laborDebtDetails.length : 0 }}/35</strong>
                                    </small>
                                </b-col>

                                <b-col xs="12" md="12" class="mb-0">
                                    <label for="taxDebtDetails"><strong>DETALLES DE LA DEUDA TRIBUTARIA</strong></label>
                                    <b-form-input id="taxDebtDetails" v-model="data.taxDebtDetails" maxlength="35"></b-form-input>
                                    <small>Anotación: <strong class="text-primary">Ingresar el detalle de la deuda tributaria (máximo 35 caracteres).</strong></small>
                                    <small class="textarea-counter-value float-right">
                                        <strong class="char-count">Caracteres: {{ data.taxDebtDetails ? data.taxDebtDetails.length : 0 }}/35</strong>
                                    </small>
                                </b-col>

                            </b-row>
                        </b-card-text>
                    </b-card>

                </validation-observer>

            </b-col>
        </b-row>

        <b-button 
            class="btn-save-data"               
            v-b-tooltip.hover.bottom.v-dark
            title="GUARDAR INFORMACIÓN"
            variant="outline-primary"
            @click="saveData"
        >            
            <feather-icon icon="SaveIcon" />
        </b-button>

        <b-button-loading 
            text="GENERAR INFORME"
            :processing="gRProcessing"
            variant="outline-primary"
            :block="true"
            size="lg"
            extra-class="mt-2"
            @process-action="generateGuaranteeReport">
        </b-button-loading>
        
    </div>
</template>

<script>

    import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonUploadFile from '@core/components/b-button-upload-file/BButtonUploadFile.vue';
    import { ref, watch, computed, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import formStoreModule from '../formStoreModule';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import router from '@/router';
    import moment from 'moment';
    import store from '@/store';
    import axios from '@axios';
    import Vue from 'vue';    
	import 'animate.css';

	Vue.use(VueSweetalert2);

    export default {
        components: {
            StatisticCardHorizontal,

            ValidationProvider,
            ValidationObserver,

            BButtonUploadFile,
            BButtonLoading,
            
            required,
            vSelect
        },
        props: {
            formData: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                moment
            }
        },
        setup(props) {

            // USE TOAST
            const toast = useToast();

            // REFS
            const gRProcessing = ref(false);
            const gFProcessing = ref(false);
            const aDProcessing = ref(false);
            const refForm = ref(null);
            const options = ref({});

            const formData = ref(props.formData);

            const data = ref({
                sentinelRiskInitial: null,
                sentinelRiskFinal: null,
                
                percent: null,
                spreadsheet: null,
                cts: null,
                gratification: null,
                thirdParties: null,
                total: null,

                posted: null,
                devolution: null,
                balance: null,

                requestedAmount: null,
                amountApproved: null,

                comments: null,

                reportPerson: null,
                sentinelRating: null,

                financialDebt: null,
                laborDebt: null,
                taxDebt: null,
                localSupplierDebt: null,
                unregularizedProtests: null,
                unpaidDocuments: null,
                checker: null,

                thirdPartiesSuggestion: null,

                laborDebtDetails: null,
                taxDebtDetails: null,

                manualDevolutionAmountStatus: null
            });

            const FORM_APP_STORE_MODULE_NAME = 'app-form';

			// REGISTER MODULE
			if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, formStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await getSelectFilters();
                setData();
            });

            // WATCH
            watch( () => data.value.manualDevolutionAmountStatus, (newValue, oldValue) => {
                if (oldValue !== null) data.value.devolution = null;
            });

            // COMPUTED
            const thirdPartiesSuggestionRules = computed(() => (data.value.devolution && data.value.devolution.includes('-') && clearField(data.value.thirdPartiesSuggestion) === null) ? true : false);

            const getSelectFilters = async () => {
                const { data } = await axios.get('/forms/edit/filters');
                options.value = data.data;
            };

            const getCurrencyFormat = (amount) => (amount ? `S/. ${parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` : null);

            const setData = () => {
                const guarantee_fund = formData.value.guarantee_fund;

                const percent = guarantee_fund.percent ? options.value.percents.find(item => item.id === guarantee_fund.percent) : null;

                const sentinelRiskInitial = guarantee_fund.initial_sentinel_risk_id ? options.value.sentinelRisks.find(item => item.value === guarantee_fund.initial_sentinel_risk_id) : null;
                const sentinelRiskFinal = guarantee_fund.final_sentinel_risk_id ? options.value.sentinelRisks.find(item => item.value === guarantee_fund.final_sentinel_risk_id) : null;

                const checker = guarantee_fund.checker_id ? options.value.checkers.find(item => item.value === guarantee_fund.checker_id) : null;
                
                const manualDevolutionAmountStatus = guarantee_fund.manual_devolution_amount_status ? 1 : 0;

                data.value = {
                    sentinelRiskInitial,
                    sentinelRiskFinal,

                    percent,
                    spreadsheet: guarantee_fund.spreadsheet,
                    cts: getCurrencyFormat(guarantee_fund.cts),
                    gratification: getCurrencyFormat(guarantee_fund.gratification),
                    thirdParties: getCurrencyFormat(guarantee_fund.third_parties),
                    total: getCurrencyFormat(guarantee_fund.total),

                    posted: guarantee_fund.posted,
                    devolution: manualDevolutionAmountStatus ? guarantee_fund.devolution : getCurrencyFormat(guarantee_fund.devolution),
                    balance: getCurrencyFormat(guarantee_fund.balance),

                    requestedAmount: guarantee_fund.requested_amount,
                    amountApproved: guarantee_fund.amount_approved,

                    comments: guarantee_fund.comments,

                    reportPerson: guarantee_fund.report_person,
                    sentinelRating: guarantee_fund.sentinel_rating,

                    financialDebt: guarantee_fund.financial_debt,
                    laborDebt: guarantee_fund.labor_debt,
                    taxDebt: guarantee_fund.tax_debt,
                    localSupplierDebt: guarantee_fund.local_supplier_debt,
                    unregularizedProtests: guarantee_fund.unregularized_protests,
                    unpaidDocuments: guarantee_fund.unpaid_documents,
                    checker,

                    thirdPartiesSuggestion: guarantee_fund.third_parties_suggestion,

                    laborDebtDetails: guarantee_fund.labor_debt_details,
                    taxDebtDetails: guarantee_fund.tax_debt_details,

                    manualDevolutionAmountStatus
                }
            };

            const recalculateValues = async () => {

                gFProcessing.value = true;

                const { spreadsheet, posted, devolution, manualDevolutionAmountStatus } = data.value;

                const id = formData.value.id;
                const formId = formData.value.id;
                const guaranteeFundId = formData.value.guarantee_fund.id;
                const monthNumber = moment(formData.value.start_date).subtract(1, 'months').format('M');
                const sentinelRiskFinal = data.value.sentinelRiskFinal ? data.value.sentinelRiskFinal.value : null;

                const payload = {
                    formId,
                    guaranteeFundId,
                    monthNumber,
                    sentinelRiskFinal,
                    spreadsheet,
                    posted,
                    devolution,
                    manualDevolutionAmountStatus: parseInt(manualDevolutionAmountStatus)
                }

                store.dispatch('app-form/recalculateValues', { id, payload })
                    .then(response => {

                        const { cts, gratification, third_parties, total, devolution, balance } = response.data.formValues;

                        data.value.cts = getCurrencyFormat(cts);
                        data.value.gratification = getCurrencyFormat(gratification);
                        data.value.thirdParties = getCurrencyFormat(third_parties);
                        data.value.total = getCurrencyFormat(total);
                        
                        if (manualDevolutionAmountStatus == '0') data.value.devolution = getCurrencyFormat(devolution);
                        data.value.balance = getCurrencyFormat(balance);

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al recalcular los valores del fondo de garantía.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    })
                    .finally( () =>  gFProcessing.value = false);

            };

            const saveData = async () => {

                gFProcessing.value = true;
                aDProcessing.value = true;

                const {
                    
                    spreadsheet, 
                    posted,
                    devolution,
                    requestedAmount, 
                    amountApproved, 
                    comments,

                    reportPerson,
                    sentinelRating,
                    financialDebt,
                    laborDebt,
                    taxDebt,
                    localSupplierDebt,
                    unregularizedProtests,
                    unpaidDocuments,
                    thirdPartiesSuggestion,

                    laborDebtDetails,
                    taxDebtDetails,

                    manualDevolutionAmountStatus
                    
                } = data.value;

                const id = formData.value.id;
                const formId = formData.value.id;
                const guaranteeFundId = formData.value.guarantee_fund.id;
                const monthNumber = moment(formData.value.start_date).subtract(1, 'months').format('M');

                const percent = data.value.percent ? data.value.percent.id : null;

                const sentinelRiskInitial = data.value.sentinelRiskInitial ? data.value.sentinelRiskInitial.value : null;
                const sentinelRiskFinal = data.value.sentinelRiskFinal ? data.value.sentinelRiskFinal.value : null;

                const checker = data.value.checker ? data.value.checker.value : null;

                const payload = {
                    formId,
                    guaranteeFundId,
                    monthNumber,
                    percent,
                    sentinelRiskInitial,
                    sentinelRiskFinal,
                    spreadsheet,
                    posted,
                    devolution,
                    requestedAmount,
                    amountApproved,
                    comments,

                    reportPerson,
                    sentinelRating,
                    financialDebt,
                    laborDebt,
                    taxDebt,
                    localSupplierDebt,
                    unregularizedProtests,
                    unpaidDocuments,
                    checker,

                    thirdPartiesSuggestion,

                    laborDebtDetails,
                    taxDebtDetails,

                    manualDevolutionAmountStatus: parseInt(manualDevolutionAmountStatus)
                }

                store.dispatch('app-form/saveForm', { id, payload })
                    .then(response => {

                        const { cts, gratification, third_parties, total, devolution, balance } = response.data.formValues;

                        data.value.cts = getCurrencyFormat(cts);
                        data.value.gratification = getCurrencyFormat(gratification);
                        data.value.thirdParties = getCurrencyFormat(third_parties);
                        data.value.total = getCurrencyFormat(total);

                        if (manualDevolutionAmountStatus == '0') data.value.devolution = getCurrencyFormat(devolution);
                        data.value.balance = getCurrencyFormat(balance);

                        data.value.thirdPartiesSuggestion = (data.value.devolution && data.value.devolution.includes('-')) ? data.value.thirdPartiesSuggestion : null;

                        data.value.reportPerson = response.data.reportPerson;

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al guardar la información del formulario.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });
                    })
                    .finally( () =>  {

                        gFProcessing.value = false;
                        aDProcessing.value = false;

                    });

            };

            const clearField = (txt) => {
                if (txt == '' || txt == ' ' || txt === null || txt === '0000-00-00' || txt === 'null' || txt === undefined) return null;
                    
                return txt;
            };

            const generateGuaranteeReport = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                if (isValid)
                {
                    Vue.swal({
                        title: '¿Está seguro(a) de generar el informe?',
                        text: "¡Si no lo está, puede cancelar la acción!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sí, generar!',
                        cancelButtonText: 'Cancelar',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1'
                        },
                        showClass: {
                            popup: 'animate__animated animate__tada'
                        },
                        buttonsStyling: false
                    }).then(result => {

                        if (result.value) 
                        {
                            gFProcessing.value = true;
                            aDProcessing.value = true;
                            gRProcessing.value = true;

                            const id = formData.value.id;

                            store.dispatch('app-form/generateGuaranteeReport', { id })
                                .then(response => {

                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: response.data.message,
                                            icon: 'CheckIcon',
                                            variant: 'success'
                                        }
                                    });

                                    router.push({ name: 'providers-edit', params: { id: response.data.id }, query: { tab: 'forms' } });

                                })
                                .catch( (err) => {
                                    let message = err.response.data.message ? err.response.data.message : 'Error al generar el informe.';
                                    
                                    toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: message,
                                            icon: 'AlertTriangleIcon',
                                            variant: 'danger'
                                        }
                                    });
                                })
                                .finally( () =>  {

                                    gFProcessing.value = false;
                                    aDProcessing.value = false;
                                    gRProcessing.value = false;

                                });
                        }

                    });
                }
                
            };

            return {
                // DATA
                gRProcessing,
                gFProcessing,
                aDProcessing,
                refForm,
                options,
                data,

                // COMPUTED
                thirdPartiesSuggestionRules,

                // METHODS
                recalculateValues,
                saveData,
                generateGuaranteeReport
            }
        }
    }

</script>

<style lang="scss" scoped>

    .btn-save-data {        
        outline: none;
        position: fixed;
        width: 55px;
        height: 55px;
        line-height: 55px;
        bottom: 30px;
        right: 30px;
        color: rgba(255, 255, 255, 0.842);
        background: var(--primary);
        transition: all 0.3s linear;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
        cursor: pointer;
    }
    
    .btn-save-data:hover,
    .btn-save-data:focus {
        color:  rgba(255, 255, 255, 0.842);
    }
    
    .btn-save-data svg {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 40px;
        top: -60%;
        left: 50%;
        transform: translateX(-50%);
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
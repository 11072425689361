import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/forms/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        recalculateValues(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/forms/${id}/recalculate-values`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        saveForm(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/forms/${id}/save`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        generateGuaranteeReport(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/forms/${id}/generate-guarantee-report`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}
<template>
    <component :is="'div'">

        <!-- Alert: No item found || item locked -->
        <b-alert
            variant="danger"
            :show="formData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del formulario</h4>
            <div class="alert-body">
                {{ errorMessage }}
            </div>
        </b-alert>

        <form-details v-if="formData" :form-data="formData" />

    </component>
</template>

<script>

    import { ref, onUnmounted } from '@vue/composition-api';
    import formStoreModule from '../formStoreModule';
    import FormDetails from './FormDetails.vue';
    import vSelect from 'vue-select';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            FormDetails,
            vSelect
        },
        setup() {

            // REFS
            const errorMessage = ref(null);
            const formData = ref(null);

            const FORM_APP_STORE_MODULE_NAME = 'app-form';

			// REGISTER MODULE
			if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, formStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
			});

            store.dispatch('app-form/fetchForm', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        formData.value = undefined
                    } else {
                        formData.value = response.data.form;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        errorMessage.value = error.response.data.message;
                        formData.value = undefined
                    }
                });

            return {
                errorMessage,
                formData
            }
        }
    }

</script>
<template>
	<div>
		
		<b-button
			v-ripple.400="'rgba(113, 102, 240, 0.15)'"	
			:size="size"
			:block="block"
			:variant="variant"
			@click="getFiles"
		>
			<feather-icon size="1x" :icon="icon" />       
			{{ text }} 
		</b-button>
		
		<b-modal
			ref="modal"
			centered
			size="xl"
			:title="title"
			modal-class="modal-primary"
			no-close-on-backdrop
			no-close-on-esc
		>

			<div class="description-modal mt-1">Descripción de la información</div>
			<div class="content-modal my-2" v-html="content"></div>
			
			<template>
				<hr>

				<div class="my-1">

					<vue-dropzone  
						ref="dropzone"
						id="dropzone"
						:options="dropzoneOptions"
						:headers="headers"
						:useCustomSlot="true"
						@vdropzone-sending-multiple="vsending"
						@vdropzone-success-multiple="vsuccess"
						@vdropzone-error="verror"	
					>
						<div class="dropzone-custom-content">
							<h4 class="dropzone-custom-title"><strong>¡Arrastre o haga click aquí para cargar contenido!</strong></h4>
							<div class="subtitle">solo archivos PDF ó EXCEL</div>
						</div>
					</vue-dropzone>
					
				</div>
				
				<hr>
			</template>

			<table class="table table-bordered text-center w-100 mt-2 mb-1">
				<thead>
					<tr>
						<th>#</th>
						<th colspan="2">Archivo</th>
						<th>Fecha de carga</th>
						<th>Opciones</th>
					</tr>
				</thead>

				<tbody>
					<tr v-if="!files.length">
						<td colspan="5">NO HAY NINGÚN ARCHIVO CARGADO.</td>
					</tr>
					<tr v-for="(item, index) in files" :key="index">							
						<td>{{ index + 1 }} </td>	
						<td colspan="2">{{ item.file_original_name }}</td>
						<td>{{ moment(item.createdAt).format('DD/MM/YYYY HH:mm A') }}</td>
						<td>
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.v-info
								variant="outline-info"
								class="mr-1 px-1"                                                 
								title="DESCARGAR"
							>
								<b-link @click=viewFile(item.id)>
									<feather-icon icon="DownloadIcon" style="color:#00cfe8"/>
								</b-link>
							</b-button>
															
							<b-button
								v-ripple.400="'rgba(234, 84, 85, 0.15)'"
								v-b-tooltip.hover.v-danger
								variant="outline-danger"								
								class="px-1"
								title="ELIMINAR"
								@click="deleteFile(item.id)"
							>
								<feather-icon icon="TrashIcon" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>

			<template #modal-footer="{ cancel }">
				<b-button variant="outline-danger" @click="cancel()">
					CERRAR
				</b-button>
			</template>

		</b-modal>
			
	</div>	
</template>

<script>

	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
	import uploadFileStoreModule from './uploadFileStoreModule';
	import { useToast } from 'vue-toastification/composition';
	import 'vue2-dropzone/dist/vue2Dropzone.min.css';
	import VueSweetalert2 from 'vue-sweetalert2';
	import Ripple from 'vue-ripple-directive';
	import vue2Dropzone from 'vue2-dropzone';
	import store from '@/store';
	import moment from 'moment';
	import axios from '@axios';
    import Vue from 'vue';    
	import 'animate.css';

	Vue.use(VueSweetalert2);

	export default {
		components: {
            vueDropzone: vue2Dropzone
        },	
		props: {
			formId: {
                type: Number,
                required: true
            },
			questionId: {
                type: Number,
                required: true
            },
            text: {
                type: String,
                default: 'CARGAR ARCHIVOS'
            },
            size: {
                type: String,
                default: 'md'
            },
            block: {
                type: Boolean,
                default: false
            },
            variant: {
                type: String,
                default: 'outline-primary'
            },
			icon: {
                type: String,
                default: 'UploadIcon'
            }
        },
		directives: {
			Ripple
		},
		data() {
			return {
				moment
			}
		},
        setup(props) {

            // USE TOAST
            const toast = useToast();

            // REFS
            const form = ref(props.formId);
			const question = ref(props.questionId);

            const headers = ref({ 'Content-Type': 'multipart/form-data' });
			const dropzone = ref(null);
			const modal = ref(null);
            const files = ref([]);

			const title = ref('');
			const content = ref('');

            const dropzoneOptions = ref({
                headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` },
				url: `${process.env.VUE_APP_HOST}/api/dashboard/files/upload`,
                method: 'post',
                paramName: 'files',
                autoDiscover: false,
                maxFiles: 10,
				timeout: 180000,
                maxThumbnailFilesize: 10,
                parallelUploads: 10,
                acceptedFiles: '.pdf,.xls,.xlsx',				
                addRemoveLinks: false,		
                autoProcessQueue: true,					
                thumbnailWidth: 140,
                thumbnailHeight: 140,
                dictRemoveFile: "Eliminar",
                uploadMultiple: true
            });

            const UPLOAD_FILE_APP_STORE_MODULE_NAME = 'app-upload-file';

            // REGISTER MODULE
            if (!store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)) store.registerModule(UPLOAD_FILE_APP_STORE_MODULE_NAME, uploadFileStoreModule);

            // UNREGISTER ON LEAVE
            onUnmounted(() => {
                if (store.hasModule(UPLOAD_FILE_APP_STORE_MODULE_NAME)) store.unregisterModule(UPLOAD_FILE_APP_STORE_MODULE_NAME);
            });

			// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( async () => {
                await getQuestionData();
            });

			const getQuestionData = async () => {
				const { data } = await axios.get(`/questions/${question.value}/view`);
				 
				title.value = data.question.title;
				content.value = data.question.description;
			};

            const getFiles = () => {

                store.dispatch('app-upload-file/fetchFiles', { formId: form.value, questionId: question.value })
                    .then( response => {

                        files.value = response.data.files;
						modal.value.show();

                    });

            };

			const viewFile = (id) => {

				store.dispatch('app-upload-file/viewFile', { id })
                    .then( response => {

						window.open(response.data.url, '_blank');

                    })
					.catch( () => {

						toast({
							component: ToastificationContent,
							props: {
								title: 'Error al descargar el archivo',
								icon: 'AlertTriangleIcon',
								variant: 'danger'
							}
						});

					});

			};

            const deleteFile = (id) => {

                Vue.swal({
                    title: '¿Está seguro(a) de eliminar el archivo?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        store.dispatch('app-upload-file/deleteFile', { id })
                            .then( response => {

                                getFiles();

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                            })
                            .catch( () => {

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el archivo',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            });
                    }

                });

            };

			const vsending = (files, xhr, formData) => {
                formData.append('form_id', form.value);
				formData.append('question_id', question.value);
                formData.append('files_count', files.length);
            };

            const vsuccess = (files, response) => {
                getFiles();

                if (dropzone.value) dropzone.value.removeAllFiles();

                Vue.swal({
                    title: '¡Actualizado!',
                    text: response.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3500
                });
            };

            const verror = (files, response, xhr) => {
                getFiles();

                if (dropzone.value) dropzone.value.removeAllFiles();

                Vue.swal({
                    title: '¡Error!',
                    text: response.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3500
                });
            };

            return {
                // DATA
                dropzoneOptions,
                headers,
				files,
				title,
				content,

                // REFS
                dropzone,
				modal,
                
                // ACTIONS
                getFiles,
				viewFile,
				deleteFile,

                vsending,
                vsuccess,
                verror
            }
        }
	}

</script>

<style lang="scss" scoped>

	.description-modal {
		font-size: 1.2rem;	
		font-weight: bold;
	}

	.content-modal {
		font-size: .995rem;
	}

</style>

<style>

    .vue-dropzone {
		border-radius: 15px !important;
		border-style: dotted !important;
		border: 2px solid #3b4253 !important;
	}
	
	.dropzone {
		background: transparent !important;
	}
	
	.dropzone .dz-preview .dz-image {
		border-radius: 10px !important; 
	}

	.dropzone .dz-preview.dz-image-preview {
		background: transparent !important;
	}
	
	.vue-dropzone > .dz-preview .dz-details {
		background: transparent !important;
	}

	.vue-dropzone > .dz-preview .dz-remove {
		margin-left: 22px !important;
	}
    
    .ql-container {
		min-height: 200px;
	}

</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>